@font-face {
    font-family: 'Poppins Black';
    src: url('./Poppins-Black.ttf') format('opentype');
  }
  
  @font-face {
    font-family: 'Poppins-bold';
    src: url('./Poppins-Bold.ttf') format('opentype');
  }
  
  @font-face {
    font-family: 'Poppins-boldItalic';
    src: url('./Poppins-BoldItalic.ttf') format('opentype');
  }
  
    
  @font-face {
    font-family: 'Poppins-Extrabold';
    src: url('./Poppins-ExtraBold.ttf') format('opentype');
  }

      
  @font-face {
    font-family: 'Poppins-Extralight';
    src: url('./Poppins-ExtraLight.ttf') format('opentype');
  }
  
  @font-face {
    font-family: 'Poppins-light';
    src: url('./Poppins-Light.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Poppins-meadiam';
    src: url('./Poppins-Medium.ttf') format('opentype');
  }

  @font-face {
    font-family: 'Poppins-semibold';
    src: url('./Poppins-SemiBold.ttf') format('opentype');
  }