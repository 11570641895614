@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.scroll-container {
  @apply scroll-smooth;
}


/* hidden proper for mobile responsive */

.res-hidden{
  @apply md:block hidden;
}

.res-hidden-md{
  @apply lg:block hidden;
}

.res-hidden-xl{
  @apply xl:block hidden;
}

/* font-family */
.Poppins-bold{
  font-family: 'Poppins-bold';
}

.Poppins-light{
  font-family: 'Poppins-light'
}

.Poppins-meadiam{
  font-family: 'Poppins-meadiam'
}

.Poppins-semibold{
  font-family: 'Poppins-semibold'
}
.Poppins-black{
  font-family: 'Poppins Black'
}

/* feature component css */
.featurebox{
  @apply md:w-[360px] mx-2 h-[230px] flex flex-col justify-center items-center border border-gray-200 rounded-xl p-2 gap-2 
}

.imgdiv{
  @apply bg-[#9848FF] rounded-lg
}

.img-size{
  @apply h-10 p-1 w-10
}

.featurebox-heading{
  @apply text-[#1C1C1C] text-[22px] font-bold;
}
.featurebox-content{
  @apply text-center text-[14px]
}





/* Footer CSS */

.fo-menu-head{
   @apply text-xl font-bold;
}

.fo-submenu{
  @apply text-[16px] font-normal cursor-pointer;
}



/* Home Page CSS */

.btn-signlog{
  @apply text-[14px] text-[#1C1C1C] py-1 px-4 rounded
}

.span2{
  @apply text-[15px] Poppins-meadiam;
}

.span1{
  @apply leading-[1.5rem] text-center text-[40px] text-black font-bold md:text-[#FFFFFF];
}

.vertical-line{
  @apply h-[50px] border-l-2 md:border-[#FFFFFF] border-black
}




/* Mission Page CSS */

.divboxMis{
  @apply bg-[#FFFFFF] p-4 text-[14px] rounded mt-4  flex items-center;
}

.shrink{
  @apply flex-shrink-0;
}

.divr{
  @apply mx-4 h-6 border-l border-black;
}

.stiky-bx{
  @apply bg-white z-50 md:sticky md:top-0;
}

.stiky-bx-1{
  @apply text-center space-y-4 pt-4 pb-8 relative;
}

.stiky-bx-h1{
  @apply text-[24px] Poppins-bold leading-6;
}

.read-yel-btn{
  @apply bg-[#FFCF59] absolute bottom-[-9px] sm:right-[4.9rem] right-[5.8rem] text-sm Poppins-bold rounded-md p-1 px-2;
}

.scroll-btn-div {
  @apply md:space-y-8 space-y-6 flex flex-col  justify-center items-center  bg-transparent ;
}
.scroll-btn-div-custom{
  
  height: 16.4rem;
  padding-bottom: 0px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.scroll-btn-div-custom::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.scroll-card {
  @apply bg-[#FFFFFF] shadow-lg  rounded-lg p-4 flex flex-col items-start space-y-2 border border-gray-300 hover:shadow-2xl transition-all duration-300 ease-in-out;
}

.scroll-card-icon {
  @apply rounded-full text-white p-2 bg-[#0F0741];
}

.scroll-card h2 {
  @apply text-xl font-semibold text-[#0F0741];
}

.scroll-card p {
  @apply text-gray-600 md:w-96 md:h-32;
}


.main-mission{
  @apply flex md:flex-row scroll-container flex-col w-full lg:px-28 md:px-1 mt-1 justify-between items-center md:h-[19rem]   md:overflow-y-auto  ;
}


/* Main Heading - Sub Heading - Paragraph */

.main-heading-t{
  @apply text-[#258bd6] md:text-[52px] text-[30px] text-center Poppins-bold ;
}

.main-heading-S{
  @apply text-[#1C1C1C] Poppins-meadiam;
}

.main-subheadings-t{
  @apply text-center md:text-[24px] text-[18px] Poppins-light;
}

.main-p{
  @apply mt-4 leading-6 text-center text-[17px] Poppins-light;
}









/* Tailwind CSS */
.container1 {
  @apply w-[100%] my-8  clear-both ;
}

.container::before,
.container::after {
  content: '';
  @apply table clear-both;
}

.right {
  @apply md:w-[450px] w-full inline-block align-top float-right;
}

.right div {
  @apply bg-white h-auto;
}

.right div + div {
  @apply mt-12;
}

.left {
  @apply lg:w-[250px] w-full h-[250px] bg-white inline-block float-left md:sticky md:top-12 md:mb-0 mb-16;
}

.sticky-container {
  @apply pointer-events-none;
}

.sticky-container * {
  @apply pointer-events-auto;
}


/* 
.main-mission {
  display: flex;
  height: 19rem;
  overflow-y: auto;
}

.stiky-bx {
  position: sticky;
  top: 0;
  background-color: #f0f0f0; 
}

.scroll-btn-div {
  padding-left: 1rem; 
} */
